
import { Component, Vue, Watch } from "vue-property-decorator";
@Component({
  metaInfo: {
    title: "Robotron - Verein zum Erhalt der Gamingkultur",
    meta: [
      {
        name: "description",
        content: "Verein zum Erhalt der gepflegten Gaming Kultur.",
      },
    ],
  },
})
export default class ApplicationView extends Vue {
  get homeLogo(): string {
    return this.$route.name === "home"
      ? require("@/assets/images/robotron/robotron2023_RZ-02.png")
      : require("@/assets/images/robotron/robotron2023_RZ-02-darkened.png");
  }
}
