var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row g-4"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"col col-6"},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"rounded mx-auto d-block img-fluid",attrs:{"src":require('@/assets/images/history/robotron_history_1.jpg'),"alt":"placeholder-image"}})])]),_vm._m(2),_c('div',{staticClass:"col col-6"},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"rounded mx-auto d-block img-fluid",attrs:{"src":require('@/assets/images/history/robotron_history_2.jpg'),"alt":"placeholder-image"}})])]),_vm._m(3),_vm._m(4),_vm._m(5),_c('div',{staticClass:"col col-6"},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"rounded mx-auto d-block img-fluid",attrs:{"src":require('@/assets/images/history/robotron_history_3.jpg'),"alt":"placeholder-image"}})])]),_vm._m(6),_c('div',{staticClass:"col col-6"},[_c('div',{staticClass:"image-container"},[_c('img',{staticClass:"rounded mx-auto d-block img-fluid",attrs:{"src":require('@/assets/images/history/robotron_history_4.jpg'),"alt":"placeholder-image"}})])]),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col col-12"},[_c('div',{staticClass:"px-3 bg-dark border rounded robo-border bg-robo-world world-2"},[_c('h1',[_vm._v("HISTORY")]),_c('p',{staticClass:"lead"},[_vm._v("Vom Wohnzimmer zur Weltherrschaft")]),_c('p',[_vm._v("Ziel zu 0.004% erreicht.")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col col-6"},[_c('div',{staticClass:"p-3 bg-dark border rounded robo-border bg-robo-schraffur h-100"},[_c('div',[_c('h2',[_vm._v("Lass mal Zocken!")]),_c('p',{staticClass:"lead lead-sm"},[_vm._v("Some Nerds | ~2010")])]),_c('p',[_vm._v(" Anfang der 2010er Jahre haben sich einige Freunde in unregelmäßigen Abständen beim “Robo” im Wohnzimmer zusammengesetzt, um auf “Oldschool” wieder Lanparties wie damals in unserer Jugend zu zelebrieren. Gespielt wurde vor allem “Counterstrike” und “Trackmania”... An den Spielen hat sich also nicht allzuviel geändert geändert gegenüber 10 Jahren vorher, als Lan-Parties noch in Mode waren. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col col-12 d-flex align-items-center"},[_c('div',{staticClass:"p-3 bg-dark border rounded robo-border bg-robo-schraffur h-100"},[_c('div',[_c('h2',[_vm._v("Ich will auch!")]),_c('p',{staticClass:"lead lead-sm"},[_vm._v("FOMO | ~2015")])]),_c('p',[_vm._v(" Um 2015 herum hat es sich im Freundeskreis herumgesprochen, dass diese Abende doch immer sehr lustig sind, und immer mehr unserer Bekannten wollten auch vorbeikommen und mitzocken. Das Wohnzimmer wurde zu klein, und Robos Lebensgefährtin wollte dann doch irgendwann ihre wohlverdiente Ruhe am Wochenende genießen… Eine Location musste her, in der wir Platz hatten und niemanden stören würden. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col col-6 d-flex align-items-center"},[_c('div',{staticClass:"p-3 bg-dark border rounded robo-border bg-robo-schraffur h-100"},[_c('div',[_c('h2',[_vm._v("Endlich Platz!")]),_c('p',{staticClass:"lead lead-sm"},[_vm._v("Kellerkinder | ~2016")])]),_c('p',[_vm._v(" Im Frühling 2016 hat Robos Schwester angeboten, dass wir in einem kleinen Partyraum in Wien Meidling ein langes Wochenende verbringen dürfen. Die erste “große” Robotron wurde ausgerufen, und es waren um die 12 Gäste aus 4 Bundesländern da, um drei Tage lang die Stromzufuhr des Partykellers zum Glühen zu bringen. Ab da wurde bis Ende 2019 zweimal pro Jahr das Fest veranstaltet. Ein Turnier inklusive Wanderpokal und Medaillen wurde eingeführt und vom Banner über Bierdeckel bis hin zu T-Shirts wurde von den Freunden in Eigenregie entworfen und produziert. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col col-12 d-flex align-items-center"},[_c('div',{staticClass:"p-3 bg-dark border rounded robo-border bg-robo-schraffur h-100"},[_c('div',[_c('h2',[_vm._v("Robotron!!1")]),_c('p',{staticClass:"lead lead-sm"},[_vm._v("All aboard the hype train | ~2017")])]),_c('p',[_vm._v(" Immer mehr Freunde kamen, und der Name Robotron hat sich für die LAN etabliert. Die Party war oft Gesprächsthema Nummer eins im Freundeskreis und die Vorfreude war groß, bevor es in die nächste Runde ging. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col col-6 d-flex align-items-center"},[_c('div',{staticClass:"p-3 bg-dark border rounded robo-border bg-robo-schraffur h-100"},[_c('div',[_c('h2',[_vm._v("Sicher nicht.")]),_c('p',{staticClass:"lead lead-sm"},[_vm._v("Gesundheitsministerium - probably | ~2020")])]),_c('p',[_vm._v(" Corona im Jahr 2020 hat dann einiges durcheinandergewürfelt. Der Partykeller musste aufgelassen werden und natürlich konnte keine Party wie geplant durchgeführt werden. Eine neue Location musste gefunden werden, und da diese nicht mehr im Familienbesitz sein wird, wurde der Aufwand, die Organisation durchzuführen, um ein Vielfaches mehr. ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col col-12 d-flex align-items-center"},[_c('div',{staticClass:"p-3 bg-dark border rounded robo-border bg-robo-schraffur h-100"},[_c('div',[_c('h2',[_vm._v("Zuviel für \"einfach so\" nebenbei.")]),_c('p',{staticClass:"lead lead-sm"},[_vm._v(" Der damalige, zukünftige, echte Robo | ~2020 ")])]),_c('p',[_vm._v(" Und so wurde Ende 2020 die Idee geboren, einen Verein zu gründen, in dem einige der Freunde die Organisation übernehmen und aufteilen würden, damit wir weiterhin, nach dem Ende der Pandemie, unserem Hobby des digitalen Kräftemessens nachkommen können. “Robotron - Verein zum Erhalt der Gamingkultur” war geboren ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col col-6 d-flex align-items-center"},[_c('div',{staticClass:"p-3 bg-dark border rounded robo-border bg-robo-schraffur h-100"},[_c('div',[_c('h2',[_vm._v("Endlich gscheit.")]),_c('p',{staticClass:"lead lead-sm"},[_vm._v("Robotron | ~2021")])]),_c('p',[_vm._v(" Seit Herbst 2021 finden wieder wie gewohnt 2x pro Jahr unsere Lan-Parties in unterschiedlichen Locations statt. Im Herbst wird im Zuge dessen die Jahreshauptversammlung abgehalten und der Vorstand für das kommende Jahr gewählt. Mittlerweile auf über 20 Core-Member angewachsen, hat der Verein auch weiterhin vor, zwei Mal pro Jahr die “guten alten Zeiten” des Gamings hoch zu halten und sich IRL zum gemeinsamen Zocken zu treffen! ")])])])
}]

export { render, staticRenderFns }