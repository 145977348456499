
import { Component, Vue } from "vue-property-decorator";
import Setup from "@/components/setup.vue";
@Component({
  components: { Setup },
})
export default class ViewSetup extends Vue {
  get tournamentIsActive() {
    return this.$store.state.active;
  }
}
