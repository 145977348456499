var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row g-4"},[_vm._m(0),_c('div',{staticClass:"col col-12"},[_c('div',{staticClass:"p-3 bg-dark border rounded robo-border bg-robo-schraffur h-100"},[_c('div',{staticClass:"row"},_vm._l((_vm.sets),function(set){return _c('div',{key:set.key,staticClass:"col col-auto"},[_c('button',{class:_vm.active === set.key
                ? 'btn btn-primary btn-sm'
                : 'btn btn-secondary btn-sm',on:{"click":function($event){return _vm.show(set.key)}}},[_vm._v(" "+_vm._s(set.title)+" ")])])}),0)])]),(_vm.active)?_c('div',{staticClass:"col col-12"},[_c('div',{staticClass:"p-3 bg-dark border rounded robo-border bg-robo-schraffur"},[(_vm.active === 'results')?_c('div',[_vm._m(1),_c('ranking-results',{attrs:{"results":_vm.results}})],1):_vm._e(),(_vm.active === 'totalIndividual')?_c('div',[_vm._m(2),_c('ranking-medals',{attrs:{"title":"Medaillienspiegel - Individuell","medal-scores":_vm.ranking.totalIndividual}})],1):_vm._e(),(_vm.active === 'totalRegion')?_c('div',[_vm._m(3),_c('ranking-medals',{attrs:{"title":"Medaillienspiegel - Region","medal-scores":_vm.ranking.totalRegion}})],1):_vm._e()])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col col-12"},[_c('div',{staticClass:"px-3 bg-dark border rounded robo-border bg-robo-world bg-robo-world competition-atomic-glade"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h1',[_vm._v("COMPETITION")]),_c('p',{staticClass:"lead"},[_vm._v("Faustvergleich")]),_c('p',{staticClass:"align-self-bottom mt-3 introductory"},[_vm._v(" Wer schießt schneller als der Schatten von allen anderen? Wer holt die meisten Medaillen in sein Bundesland? ")])])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h2',[_vm._v("Timeline")]),_c('p',{staticClass:"lead lead-sm"},[_vm._v("zum Nachlesen")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h2',[_vm._v("Leaderboard")]),_c('p',{staticClass:"lead lead-sm"},[_vm._v("zum Angeben")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('h2',[_vm._v("Regions")]),_c('p',{staticClass:"lead lead-sm"},[_vm._v("zum Zusammenhalten")])])])
}]

export { render, staticRenderFns }