
import { Component, Vue } from "vue-property-decorator";
import Home from "@/components/home.vue";
@Component({
  components: { Home },
})
export default class ViewHome extends Vue {
  get tournamentIsActive() {
    return this.$store.state.active;
  }
}
