import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

const players: Array<string> = [];
const ffaGames: Array<{ name: string; image: unknown }> = [
  {
    name: "A",
    image: require("@/assets/images/games/A.png"),
  },
  {
    name: "B",
    image: require("@/assets/images/games/B.png"),
  },
  {
    name: "C",
    image: require("@/assets/images/games/C.png"),
  },
  {
    name: "D",
    image: require("@/assets/images/games/D.png"),
  },
  {
    name: "E",
    image: require("@/assets/images/games/E.png"),
  },
];

export default new Vuex.Store({
  state: () => ({
    active: "setup",
    data: {
      players: players,
      ffa: {
        games: ffaGames,
        active: null,
        states: {
          revealed: false,
          done: true,
        },
      },
    },
  }),
  getters: {},
  mutations: {
    pushPlayer(state, player: string) {
      state.data.players.push(player);
    },
    setTournamentState(state, active: string) {
      state.active = active;
    },
    setCurrentGameIsRevealed(state, isRevealed: boolean) {
      state.data.ffa.states.revealed = isRevealed;
    },
    setCurrentGameIsDone(state, isDone: boolean) {
      state.data.ffa.states.done = isDone;
    },
    setCurrentGame(state, game: { name: string; image: unknown }) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      state.data.ffa.active = game;
    },
  },
  modules: {},
});
