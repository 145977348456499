
import { Component, Vue } from "vue-property-decorator";
import RankingMedals from "./ranking/medals.vue";
@Component({
  components: { RankingMedals },
})
export default class Home extends Vue {
  private readonly ranking = require("@/assets/js/ranking");
  linkToHistory() {
    this.$router.push("/history");
  }
  linkToRanking() {
    this.$router.push("/ranking");
  }
  get eventIsDone(): boolean {
    return (
      new Date(new Date(2024, 9, 12).toDateString()) <
      new Date(new Date().toDateString())
    );
  }
  get headerContainerClass(): string {
    let worlds = [
      "world-acid-valley",
      "world-anteanna-city",
      "world-crackling-mountains",
      "world-data-sea",
      "world-lake-coolant",
      "world-lithium-forest",
      "world-the-great-anode",
    ];
    return (
      "px-3 bg-dark border rounded robo-border bg-robo-world bg-robo-world " +
      worlds[Math.floor(Math.random() * worlds.length)]
    );
  }
}
