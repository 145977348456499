import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import "bootstrap";
import axios from "axios";
import VueAxios from "vue-axios";

Vue.config.productionTip = false;
Vue.use(VueMeta);
Vue.use(VueAxios, axios);

// Import the Auth0 configuration
import { domain, clientId, redirectUri } from "../auth_config.json";
// Import the plugin here
import { Auth0Plugin } from "./plugins/auth";

Vue.use(Auth0Plugin, {
  domain,
  clientId,
  redirectUri,
  onRedirectCallback: (appState: any) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
