
import { Component, Vue } from "vue-property-decorator";
import Lightbox from "@/components/_lib/lightbox.vue";

@Component({
  components: { Lightbox },
})
export default class Images extends Vue {
  private activeImageSet: string | null = null;
  private sets: Array<string> = ["18.1", "21.1"];

  private activeEntry: number = 0;

  setImageSet(name: string) {
    this.activeImageSet = name;
  }

  private imagePaths: Array<string> = [
    require("@/assets/images/impressions/robotron_impressions_1.jpg"),
    require("@/assets/images/impressions/robotron_impressions_2.jpg"),
    require("@/assets/images/impressions/robotron_impressions_3.jpg"),
    require("@/assets/images/impressions/robotron_impressions_4.jpg"),
    require("@/assets/images/impressions/robotron_impressions_5.jpg"),
    require("@/assets/images/impressions/robotron_impressions_6.jpg"),
    require("@/assets/images/impressions/robotron_impressions_7.jpg"),
    require("@/assets/images/impressions/robotron_impressions_8.jpg"),
  ];

  setActiveEntry(index: number) {
    this.activeEntry = index;
  }

  addActiveEntry(addition: number) {
    this.activeEntry += addition;
    if (this.activeEntry > this.imagePaths.length - 1) {
      this.activeEntry = 0;
    }
    if (this.activeEntry < 0) {
      this.activeEntry = this.imagePaths.length - 1;
    }
  }
}
