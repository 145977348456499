var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-light",attrs:{"id":"app"}},[_c('div',{staticClass:"container p-0"},[_c('nav',{staticClass:"navbar navbar-expand-md navbar-dark px-3 py-0 bg-dark border rounded robo-border"},[_vm._m(0),_c('router-link',{staticClass:"navbar-brand",attrs:{"to":"/"}},[_c('img',{staticClass:"d-inline-block align-text-top",attrs:{"src":_vm.homeLogo,"alt":"Logo","width":"112","height":"63"}})]),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarCollapse"}},[_c('ul',{staticClass:"navbar-nav mr-auto"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.$route.name === 'ranking'
                  ? 'nav-link text-secondary active'
                  : 'nav-link text-secondary',attrs:{"to":"/ranking"}},[_vm._v("Ranking")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.$route.name === 'images'
                  ? 'nav-link text-secondary active'
                  : 'nav-link text-secondary',attrs:{"to":"/images"}},[_vm._v("Impressions")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{class:_vm.$route.name === 'history'
                  ? 'nav-link text-secondary active'
                  : 'nav-link text-secondary',attrs:{"to":"/history"}},[_vm._v("History")])],1)])])],1)]),_c('div',{staticClass:"content"},[_c('router-view')],1),_c('div',{staticClass:"footer"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col text-secondary d-flex align-items-center ps-5"},[_c('router-link',{class:_vm.$route.name === 'impressum'
              ? 'nav-link text-secondary active'
              : 'nav-link text-secondary',attrs:{"to":"/impressum"}},[_vm._v("Impressum")])],1),_c('div',{staticClass:"col col-auto text-secondary d-flex align-items-center pe-5"},[_vm._v(" © ROBOTRON – Verein zum Erhalt der Gamingkultur ")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarCollapse","aria-controls":"navbarToggleExternalContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
}]

export { render, staticRenderFns }