
import { Component, Vue } from "vue-property-decorator";
@Component({
  props: {
    results: {
      required: true,
    },
  },
})
export default class RankingResults extends Vue {
  private results!: unknown;
  getPathOfOrigin(origin: string) {
    switch (origin) {
      case "Tirol":
        return require("@/assets/images/regions/TRL.png");
      case "Wien":
        return require("@/assets/images/regions/WN.png");
      case "Kärnten":
        return require("@/assets/images/regions/KRNT.png");
      case "Steiermark":
        return require("@/assets/images/regions/STMK.png");
      case "Oberösterreich":
        return require("@/assets/images/regions/OOe.png");
      case "Niederösterreich":
        return require("@/assets/images/regions/NOe.png");
      case "Vorarlberg":
        return require("@/assets/images/regions/VRLB.png");
      case "Salzburg":
        return require("@/assets/images/regions/SBG.png");
      case "Burgenland":
        return require("@/assets/images/regions/BGL.png");
      default:
        return require("@/assets/images/regions/UDF.png");
    }
  }
}
